<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title style="float: left">Consulta BD: {{ consulta.nombre }} </v-card-title>

      

      <v-card-text>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel>
            <v-card-text class="subnote">Información No Oficial, en caso de requerir un reporte oficialmente realizarlo a través de la plataforma de Titan.</v-card-text>
            <v-expansion-panel-header> Opciones de consulta</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-form v-model="valid">
                  <v-row>
                    <v-col cols="12" md="12">
                      <template v-for="x, index in subConsulta.campos_busqueda">
                        </hr>
                      </template>
                      
                      <table class="tabla">
                        <thead class="v-data-table-header">
                          <tr>
                            <th class="text-left">
                              Filtro
                            </th>
                            <th class="text-left">
                              Valor
                            </th>
                            <th class="text-center">
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in subConsulta.campos_busqueda"
                            :key="item.name"
                          >
                            <td>
                              <div
                                v-if="loadingCampos"
                                class="spinner-border"
                                role="status"
                              ></div>
                              <v-select
                                  v-if="!loadingCampos"
                                  v-model="item.filtro"
                                  :items="campos"
                                  :item-text="'nombre_alias'"
                                  :item-value="'nombre'"
                                  attach
                                  label="Criterio de búsqueda"
                                  :rules="required"
                                ></v-select>
                            </td>
                            <td>
                              <v-text-field
                                v-model="item.valor"
                                label="Valor"
                                single-line
                                counter
                                hide-details="auto"
                                :rules="required"
                              ></v-text-field>
                            </td>
                            <td class="text-center">
                              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      
                      </table>
                      
                      <v-row>
                        <v-col cols="12" md="12" class="text-right">
                          <CButton
                            v-if="true"
                            color="primary"
                            class="px-4"
                            @click="agregarFiltro()"
                            >+ Filtro</CButton
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                    <v-col cols="12" md="12">
                      <div
                        v-if="loadingCampos"
                        class="spinner-border"
                        role="status"
                      ></div>
                      <v-select
                        v-if="!loadingCampos"
                        v-model="subConsulta.campos_salida"
                        :items="opcionesCamposSalida"
                        :item-text="'nombre_alias'"
                        :item-value="'nombre'"
                        attach
                        chips
                        label="Campos de salida"
                        :rules="required"
                        multiple
                        return-object
                      >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggle"
                        >
                          <v-list-item-action>
                            <v-icon :color="subConsulta.campos_salida.length > 0 ? 'indigo darken-4' : ''">
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Seleccionar todos los campos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="text-center">
                      <CButton
                        color="primary"
                        class="px-4"
                        :disabled="!(valid && subConsulta.campos_salida.length)"
                        @click="consultar()"
                        ><CIcon name="cil-magnifying-glass" /> Obtener consulta</CButton
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status"></div>
        </div>
        
        <v-container v-if="items.length">      
          <v-row>
            <v-col cols="12" md="9">
              <v-text-field
                v-model="search"
                label="Buscar en los resultados"
                class="mx-4"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-spacer></v-spacer>

              <v-btn
                color="success"
                class="ma-2 white--text"
                @click="descargar()"
                :disabled="loadingDescargar"
              >
                Descargar
                <v-icon
                  right
                  dark
                >
                  mdi-file-download
                </v-icon>
                &nbsp;<div class="spinner-border" role="status" v-if="loadingDescargar"></div>
              </v-btn>

            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          item-key="name"
          class="elevation-1"
          :search="search"
          :footer-props="{
            'items-per-page-options': [10, 50, 100, 200, 500, -1]
          }"
          :items-per-page="50"
          :loading='loading'
          loading-text="Cargando..."
        >
          
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="verPanoramico(item)"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
        <small>Se visualizarán máximo 5.000 registros.</small>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="goBack">Volver</CButton>&nbsp;
      </v-card-actions>
    </v-card>
    <br><br>

      <v-dialog
        v-model="dialog"
        transition="dialog-top-transition"
        width="98%"
        height="90"
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="#BBD000"
              dark
            >Detalle Panorámico</v-toolbar>
            <v-card-text>
              

              <div class="my-3 panoramico">
                  
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  color="basil"
                  grow
                  show-arrows
                >
                  <v-tab
                    v-for="item in itemsTabs"
                    :key="item"
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item
                    v-for="item, index in itemsTabs"
                    :key="item"
                  >
                    <div v-if="index == 0">
                      <v-container class="grey lighten-5">
                        <v-row no-gutters>
                          <v-col
                            v-for="n, i in consulta.campos_panoramico_1"
                            :key="n"
                            cols="12"
                            sm="4"
                          >
                            <v-card
                              class="pa-2"
                              outlined
                              tile
                            >
                              <v-text-field
                                :label="getAliasByNombre(consulta.campos_panoramico_1[i])"
                                readonly
                                v-bind:value="getValorCampoByNombre(consulta.campos_panoramico_1[i], itemPanoramico)"
                              ></v-text-field>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>  
                    </div>

                    <div v-if="index == 1">
                      <v-container class="grey lighten-5">
                        <v-row no-gutters>
                          <v-col
                            v-for="n, i in consulta.campos_panoramico_2"
                            :key="n"
                            cols="12"
                            sm="4"
                          >
                            <v-card
                              class="pa-2"
                              outlined
                              tile
                            >
                              <v-text-field
                                :label="getAliasByNombre(consulta.campos_panoramico_2[i])"
                                readonly
                                v-bind:value="getValorCampoByNombre(consulta.campos_panoramico_2[i], itemPanoramico)"
                              ></v-text-field>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                    <div v-if="index == 2">
                      <v-row no-gutters>
                          <v-col
                            v-for="n, i in consulta.campos_panoramico_3"
                            :key="n"
                            cols="12"
                            sm="4"
                          >
                            <v-card
                              class="pa-2"
                              outlined
                              tile
                            >
                              <v-text-field
                                :label="getAliasByNombre(consulta.campos_panoramico_3[i])"
                                readonly
                                v-bind:value="getValorCampoByNombre(consulta.campos_panoramico_3[i], itemPanoramico)"
                              ></v-text-field>
                            </v-card>
                          </v-col>
                        </v-row>
                    </div>
                    <div v-if="index == 3">
                      <v-row no-gutters>
                          <v-col
                            v-for="n, i in consulta.campos_panoramico_4"
                            :key="n"
                            cols="12"
                            sm="4"
                          >
                            <v-card
                              class="pa-2"
                              outlined
                              tile
                            >
                              <v-text-field
                                :label="getAliasByNombre(consulta.campos_panoramico_4[i])"
                                readonly
                                v-bind:value="getValorCampoByNombre(consulta.campos_panoramico_4[i], itemPanoramico)"
                              ></v-text-field>
                            </v-card>
                          </v-col>
                        </v-row>
                    </div>
                    
                  </v-tab-item>
                </v-tabs-items>
              </div>


            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                @click="cerrarPanoramico()"
              >Volver</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

  </div>
</template>

<script>
import consultaAutogestorService from "@/services/definicionConsulta.service";
import ListaService from "@/services/lista.service";
import CampoService from "@/services/campo.service";
import { mapState } from "vuex";
import EventBus from "@/common/EventBus";

export default {
  name: "Layout",
  data() {
    return {
      consulta: {
        nombre: undefined,
        descripcion: undefined,
        categoria_id: undefined,
        layout_id: undefined,
        campos_consulta: [],
        campos_panoramico_1: [],
        campos_panoramico_2: [],
        campos_panoramico_3: [],
        campos_panoramico_4: [],
        roles_acceso: [],
        activo: true,
      },
      subConsulta: {
        campos_busqueda: [], 
        campos_salida: [],
        idTemp: '',
        currentdate: null,
        consulta: null
      },
      panel: [1,0],
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
      camposBusqueda: [],
      campos: [],
      opcionesCamposSalida: [],
      headers: [],
      items: [],
      search: null,
      headersFiltros: [
        { text: "Filtro", value: "filtro", sortable: false },
        { text: "Valor", value: "valor", sortable: false },
        { text: "Borrar", value: "actions", sortable: false },
      ],
      loading: false,
      loadingDescargar: false,
      loadingLabelsPanoramicos: false,
      loadingCampos: false,
      message: "",
      dialog: false,
      tab: null,
      itemsTabs: [
        '.', '..', '...', '....',
      ],
      itemPanoramico: undefined
    };
  },
  components: {

  },
  watch: {
    panel(val){
      console.log(this.panel)
    }
  },
  computed: {
    ...mapState(["permissions"]),
    likesAll () {
        return this.subConsulta.campos_salida.length === this.opcionesCamposSalida.length
    },
    likesSome () {
      return this.subConsulta.campos_salida.length > 0 && !this.likesAll
    },
    icon () {
        if (this.likesAll) return 'mdi-close-box'
        if (this.likesSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  mounted() {
    if (!this.permissions.includes("Autogestor - Consulta"))
      this.$router.push("/dashboard");
    
    if (localStorage.getItem("datos_autogestor_preconsulta")) {
      this.consulta = JSON.parse(
        localStorage.getItem("datos_autogestor_preconsulta")
      );
      this.consulta.campos_consulta = JSON.parse(this.consulta.campos_consulta);
      this.consulta.campos_panoramico_1 = JSON.parse(this.consulta.campos_panoramico_1)
      this.consulta.campos_panoramico_2 = JSON.parse(this.consulta.campos_panoramico_2)
      this.consulta.campos_panoramico_3 = JSON.parse(this.consulta.campos_panoramico_3)
      this.consulta.campos_panoramico_4 = JSON.parse(this.consulta.campos_panoramico_4)
      this.consulta.roles_acceso = JSON.parse(this.consulta.roles_acceso)

      this.subConsulta.consulta = this.consulta
      
      this.fetchCampos("")
    }

    if (localStorage.getItem("temp_autogestor_consulta")){
      if(this.consulta.nombre == localStorage.getItem("temp_autogestor_consulta")) {
        //alert(localStorage.getItem("temp_autogestor_consulta"))
        //console.log('Datos a traer de localstorage')
        this.subConsulta = JSON.parse(localStorage.getItem("temp_autogestor_subconsulta"))
        //console.log(this.subConsulta)
        this.consultarResultado()
      }
    }

    this.fetchLabelsPanoramicos("")
    // this.agregarFiltro()
  },
  methods: {
    fetchLabelsPanoramicos(cadenaBusqueda) {
      this.loadingLabelsPanoramicos = true;
        if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      ListaService.getLabelsPanoramicos(cadenaBusqueda).then(
        (response) => {
          this.labelsPanoramicos = response.data.data;
          this.itemsTabs = this.labelsPanoramicos.map(item => item.valor_texto)
          this.loadingLabelsPanoramicos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingLabelsPanoramicos = false;
        }
      );
    },
    fetchCampos(cadenaBusqueda) {
      this.loadingCampos = true;
      if(cadenaBusqueda=="")
        cadenaBusqueda="?activo=1";
      else
        cadenaBusqueda=cadenaBusqueda+"&activo=1";
      CampoService.getItems(this.consulta.layout_id, cadenaBusqueda).then(
        (response) => {
          this.campos = response.data;
          
          this.campos = this.campos.map(
            function f(x) {
              let alias = x.alias?x.alias:x.nombre
              return { ...x, nombre_alias: `${alias}` };
            }
          );
          this.opcionesCamposSalida = this.campos.filter( item => this.consulta.campos_consulta.includes(item.nombre))
          this.loadingCampos = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loadingCampos = false;
        }
      );
    },
    getCampoById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre:''
    },
    getAliasById(id){
      let campo = this.campos.find(e => e.id == id)
      return campo?campo.nombre_alias:''
    },
    getAliasByNombre(nombre){
      let campo = this.campos.find(e => e.nombre == nombre)
      return campo?campo.nombre_alias:''
    },
    getValorCampoByNombre(nombre, item){
      return item?item[this.depurar_cadena(nombre)]:''
    },
    consultar() {
      this.items = []
      this.loading = true;
      this.headers = []
      this.subConsulta.currentdate = new Date()

      this.subConsulta.idTemp = 'Temp'+this.hashCode(JSON.stringify(this.subConsulta)) 

      localStorage.setItem("temp_autogestor_consulta", this.consulta.nombre)
      localStorage.setItem("temp_autogestor_subconsulta", JSON.stringify(this.subConsulta))
      // console.log('hash....')
      // console.log(this.subConsulta.idTemp) 
   
      consultaAutogestorService.consultarTest(this.subConsulta).then(
        (response) => {

          localStorage.removeItem("temp_autogestor_consulta");
          localStorage.removeItem("temp_autogestor_subconsulta");

          for(let campo = 0; campo < this.subConsulta.campos_salida.length; campo++) {
            this.headers.push({
              text: this.subConsulta.campos_salida[campo].nombre_alias,
              value: this.depurar_cadena(this.subConsulta.campos_salida[campo].nombre),
            });
          }
          this.headers.push({
            text: 'Panorámico',
            align: 'center',
            value: 'actions',
          });
         
          console.log("Resultado consulta:")
          console.log(response.data)
          this.items = response.data
          this.loading = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loading = false
            console.log("Está tardando más de lo usual.  El sistema seguirá esperando el resultado.")
            this.consultarResultado()
        }
      )
    },
    consultarResultado() {
      return null
      console.log("Reintentando consulta de resultado...")
      this.items = []
      this.loading = true;
      this.headers = []
      
      consultaAutogestorService.consultarResultado(this.subConsulta).then(
        (response) => {

          console.log(response.data)

          if(response.data != 'Pendiente') {

            localStorage.removeItem("temp_autogestor_consulta");
            localStorage.removeItem("temp_autogestor_subconsulta");

            for(let campo = 0; campo < this.subConsulta.campos_salida.length; campo++) {
              this.headers.push({
                text: this.subConsulta.campos_salida[campo].nombre_alias,
                value: this.subConsulta.campos_salida[campo].nombre,
              });
            }
            this.headers.push({
              text: 'Panorámico',
              align: 'center',
              value: 'actions',
            });
          
            // console.log("Resultado consulta:")
            console.log(response.data)
            this.items = response.data
            this.loading = false
          }
          else {
            setTimeout(this.consultarResultado, 30.0*1000);
          }
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            console.log("presentación del error:")
            console.log(error)
            this.loading = false
        }
      )
    },
    agregarFiltro() {
      this.subConsulta.campos_busqueda.push({
        filtro: "",
        valor: "",
      });
    },
    deleteItem(item) {
      let i = this.subConsulta.campos_busqueda.indexOf(item);
      this.subConsulta.campos_busqueda.splice(i, 1);
    },
    verPanoramico(item){
      this.dialog = true
      this.itemPanoramico = item
    },
    cerrarPanoramico(){
      this.dialog = false
    },
    descargar() {
      this.loadingDescargar = true
      consultaAutogestorService.getArchivo(this.subConsulta.idTemp).then(
        (response) => {
          var blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          var fileName = `Consulta ${this.consulta.nombre}.csv`;
          this.saveAs(blob, fileName);
          this.loadingDescargar = false
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
            this.loadingDescargar = false
        }
      );
    },
    saveAs(blob, fileName) {
      var url = window.URL.createObjectURL(blob);

      var anchorElem = document.createElement("a");
      anchorElem.style = "display: none";
      anchorElem.href = url;
      anchorElem.download = fileName;

      document.body.appendChild(anchorElem);
      anchorElem.click();

      document.body.removeChild(anchorElem);

      // On Edge, revokeObjectURL should be called only after
      // a.click() has completed, atleast on EdgeHTML 15.15048
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    hashCode (cadena) {
      var hash = 0,
        i, chr;
      if (cadena.length === 0) return hash;
      for (i = 0; i < cadena.length; i++) {
        chr = cadena.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    depurar_cadena (text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      text = text.replace(/ /g,"_");
      text = text.replace(/"""KEY"""/g,"_key");
      text=text.replace(/[^a-z0-9\_\-]/gi,"")
      if (text == "KEY")
        text = "_key"
      text = text.toLowerCase();
      console.log("depuracion cadena:"+text);
      return text
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.subConsulta.campos_salida = []
        } else {
          this.subConsulta.campos_salida = this.opcionesCamposSalida.slice()
        }
      })
    },
    goBack() {
      this.$router.push({ path: "/autogestor/categorias/consultas" });
    },
  },
};
</script>

<style>

.v-dialog__content {
    z-index: 1030 !important;
}
.panoramico .v-input__control {
  height: 30px;
}

.tabla{
  width: 100%;
  border-width: 0.5px;
}
.tabla th{
  color: rgba(0,0,0,.6);
  font-size: .75rem;
  padding-left: 16px;
  padding-right: 16px;
  height: 48px;
}
.tabla, .tabla tr {
  border: 1px solid;
  border-collapse: collapse;
  border-color: #e1e1e1;
}
.tabla td {
  padding-left: 16px;
  padding-right: 16px;
}
</style>
