import { render, staticRenderFns } from "./Consulta2.vue?vue&type=template&id=a845c426&"
import script from "./Consulta2.vue?vue&type=script&lang=js&"
export * from "./Consulta2.vue?vue&type=script&lang=js&"
import style0 from "./Consulta2.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports